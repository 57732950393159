import { Button, ToggleButton, styled } from "@mui/material";

const CalendarButton = styled(ToggleButton)({
    padding: '0.417vw 1.042vw',
    border: '1px solid transparent',
    color: '#181818',
    border: '1px solid #BDBDBD',
    textTransform: 'none',
    borderRadius: 0,
    fontSize: '0.833vw',
    '&:hover': {
        borderColor: '#181818',
        backgroundColor: 'inherit',
    },

    '&.Mui-selected': {
        border: '1px solid transparent',
        backgroundColor: '#8AB528',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#8AB528',
        },
    },
});

export default CalendarButton;
