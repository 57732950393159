import React from 'react';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Stack } from '@mui/material';
import DeleteIcon from '../svgComponents/DeleteIcon';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30.833vw',
    minHeight: '21.146vw',
    bgcolor: 'background.paper',
    padding: '2.604vw 3.75vw',
    fontFamily: 'IBM Plex Sans',
    boxSizing: 'border-box',
    outline: 'none',
    fontSize: '1.25vw',
};

export default function ModalArchive({ isOpen, onClose, countDocuments, putDocumentsInArchive, uncheckAll }) {
    const declOfNum = (value, words) => {
        value = Math.abs(value) % 100;
        var num = value % 10;
        if (value > 10 && value < 20) return words[2];
        if (num > 1 && num < 5) return words[1];
        if (num == 1) return words[0];
        return words[2];
    };

    return (
        <Modal open={isOpen} onClose={onClose}>
            <Box sx={style}>
                <div className="signature-close" onClick={onClose}>
                    <DeleteIcon />
                </div>
                <Stack alignItems={'center'} justifyContent={'space-between'}>
                    <Box
                        sx={{
                            lineHeight: '1.625vw',
                            fontWeight: '500',
                        }}
                    >
                        {declOfNum(countDocuments, ['Выбран', 'Выбрано', 'Выбрано'])} {countDocuments}{' '}
                        {declOfNum(countDocuments, ['пакет', 'пакета', 'пакетов'])} документов для перемещения в архив
                    </Box>
                    <Box>
                        <button
                            className="signature-sign"
                            onClick={async () => {
                                onClose();
                                await putDocumentsInArchive();
                            }}
                        >
                            Переместить
                        </button>
                        <div
                            onClick={() => {
                                onClose();
                                uncheckAll();
                            }}
                            className="signature-cancel"
                        >
                            Отменить
                        </div>
                    </Box>
                </Stack>
            </Box>
        </Modal>
    );
}
