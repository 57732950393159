import { createSlice } from '@reduxjs/toolkit';
import { documentsApi } from 'store/documentsApi';

const initialState = {
    countIncoming: 0,
    countOutgoing: 0,
    countIncomingArchive: 0,
    countOutgoingArchive: 0,
    incomingDocuments: [],
    outgoingDocuments: [],
    incomingArchiveDocuments: [],
    outgoingArchiveDocuments: [],
    filteredIncomingDocuments: [],
    filteredOutgoingDocuments: [],
    incomingFilteredArchiveDocument: [],
    outgoingFilteredArchiveDocument: [],
    selectedIncomingDocuments: [],
    selectedOutgoingDocuments: [],
};

const documentsSlice = createSlice({
    name: 'documents',
    initialState,
    reducers: {
        setIncomingDocuments: (state, action) => {
            state.incomingDocuments = action.payload;
        },
        setOutgoingDocuments: (state, action) => {
            state.outgoingDocuments = action.payload;
        },
        setIncomingArchiveDocuments: (state, action) => {
            state.incomingArchiveDocuments = action.payload;
        },
        setOutgoingArchiveDocuments: (state, action) => {
            state.outgoingArchiveDocuments = action.payload;
        },
        setFilteredIncomingDocuments: (state, action) => {
            state.filteredIncomingDocuments = action.payload;
        },
        setFilteredOutgoingDocuments: (state, action) => {
            state.filteredOutgoingDocuments = action.payload;
        },
        setIncomingFilteredArchiveDocuments: (state, action) => {
            state.incomingFilteredArchiveDocument = action.payload;
        },
        setOutgoingFilteredArchiveDocuments: (state, action) => {
            state.outgoingFilteredArchiveDocument = action.payload;
        },
        addSelectedIncomingDocument: (state, action) => {
            state.selectedIncomingDocuments.push(action.payload);
        },
        deleteSelectedIncomingDocument: (state, action) => {
            state.selectedIncomingDocuments = state.selectedIncomingDocuments.filter((document) => document.id !== action.payload.id);
        },
        addSelectedOutgoingDocument: (state, action) => {
            state.selectedOutgoingDocuments.push(action.payload);
            state.countDocumentsForRevalidate = state.selectedOutgoingDocuments.length;
        },
        deleteSelectedOutgoingDocument: (state, action) => {
            state.selectedOutgoingDocuments = state.selectedOutgoingDocuments.filter((document) => document.id !== action.payload.id);
            state.countDocumentsForRevalidate = state.selectedOutgoingDocuments.length;
        },
        clearSelectedIncomingDocuments: (state, _) => {
            state.selectedIncomingDocuments = [];
        },
        clearSelectedOutgoingDocuments: (state, _) => {
            state.selectedOutgoingDocuments = [];
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(documentsApi.endpoints.getIncomingDocuments.matchFulfilled, (state, action) => {
            state.countIncoming = action.payload.filter((document) => document.is_read === false).length;
            state.incomingDocuments = action.payload;
            state.selectedIncomingDocuments = [];
        });
        builder.addMatcher(documentsApi.endpoints.getOutgoingDocuments.matchFulfilled, (state, action) => {
            state.countOutgoing = action.payload.filter((document) => document.is_read === false).length;
            state.outgoingDocuments = action.payload;
            state.selectedOutgoingDocuments = [];
        });
        builder.addMatcher(documentsApi.endpoints.getArchive.matchFulfilled, (state, action) => {
            state.countIncomingArchive = action.payload.incoming_files.filter((document) => document.is_read === false).length;
            state.countOutgoingArchive = action.payload.outgoing_files.filter((document) => document.is_read === false).length;
            state.incomingArchiveDocuments = action.payload.incoming_files;
            state.outgoingArchiveDocuments = action.payload.outgoing_files;
            state.selectedIncomingDocuments = [];
            state.selectedOutgoingDocuments = [];
        });
    },
});

export const {
    setIncomingDocuments,
    setOutgoingDocuments,
    addSelectedIncomingDocument,
    deleteSelectedIncomingDocument,
    addSelectedOutgoingDocument,
    deleteSelectedOutgoingDocument,
    setFilteredIncomingDocuments,
    setFilteredOutgoingDocuments,
    clearSelectedIncomingDocuments,
    clearSelectedOutgoingDocuments,
    setCountDocumentsForSign,
    setCountDocumentsForRevalidate,
    setIncomingArchiveDocuments,
    setOutgoingArchiveDocuments,
    setIncomingFilteredArchiveDocuments,
    setOutgoingFilteredArchiveDocuments,
} = documentsSlice.actions;

export default documentsSlice.reducer;
