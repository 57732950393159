const BackIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="1.25vw" height="1.25vw" viewBox="0 0 24 25" fill="none">
            <path
                d="M20.975 13.5V20.5C20.975 21.1 20.575 21.5 19.975 21.5C19.375 21.5 18.975 21.1 18.975 20.5V13.5C18.975 11.8 17.675 10.5 15.975 10.5H6.375L9.675 13.8C10.075 14.2 10.075 14.8 9.675 15.2C9.475 15.4 9.275 15.5 8.975 15.5C8.675 15.5 8.475 15.4 8.275 15.2L3.275 10.2C3.175 10.1 3.075 10 3.075 9.9C2.975 9.6 2.975 9.4 3.075 9.1C3.175 9 3.175 8.9 3.275 8.8L8.275 3.8C8.675 3.4 9.275 3.4 9.675 3.8C10.075 4.2 10.075 4.8 9.675 5.2L6.375 8.5H15.975C18.775 8.5 20.975 10.7 20.975 13.5Z"
                fill="#212529"
            />
            <mask id="mask0_3286_16011" maskUnits="userSpaceOnUse" x="3" y="3" width="18" height="19">
                <path
                    d="M20.975 13.5V20.5C20.975 21.1 20.575 21.5 19.975 21.5C19.375 21.5 18.975 21.1 18.975 20.5V13.5C18.975 11.8 17.675 10.5 15.975 10.5H6.375L9.675 13.8C10.075 14.2 10.075 14.8 9.675 15.2C9.475 15.4 9.275 15.5 8.975 15.5C8.675 15.5 8.475 15.4 8.275 15.2L3.275 10.2C3.175 10.1 3.075 10 3.075 9.9C2.975 9.6 2.975 9.4 3.075 9.1C3.175 9 3.175 8.9 3.275 8.8L8.275 3.8C8.675 3.4 9.275 3.4 9.675 3.8C10.075 4.2 10.075 4.8 9.675 5.2L6.375 8.5H15.975C18.775 8.5 20.975 10.7 20.975 13.5Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_3286_16011)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0.5H24V24.5H0V0.5Z" fill="#949DAF" />
            </g>
        </svg>
    );
};

export default BackIcon;
