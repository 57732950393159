import { Paper, styled } from "@mui/material";

const DatePickerWrapper = styled(Paper)({
    padding: '1.563vw',
    marginTop: '10px',
    width: '42.708vw',
    borderRadius: 0,
    boxSizing: 'border-box',
});

export default DatePickerWrapper;
