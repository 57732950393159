const CalendarIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="1.458vw" height="1.458vw" viewBox="0 0 28 28" fill="none">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M22.1663 3.49984H19.833V2.33317C19.833 1.63317 19.3663 1.1665 18.6663 1.1665C17.9663 1.1665 17.4997 1.63317 17.4997 2.33317V3.49984H10.4997V2.33317C10.4997 1.63317 10.033 1.1665 9.33301 1.1665C8.63301 1.1665 8.16634 1.63317 8.16634 2.33317V3.49984H5.83301C3.84967 3.49984 2.33301 5.0165 2.33301 6.99984V23.3332C2.33301 25.3165 3.84967 26.8332 5.83301 26.8332H22.1663C24.1497 26.8332 25.6663 25.3165 25.6663 23.3332V6.99984C25.6663 5.0165 24.1497 3.49984 22.1663 3.49984ZM5.83301 5.83317H8.16634V6.99984C8.16634 7.69984 8.63301 8.1665 9.33301 8.1665C10.033 8.1665 10.4997 7.69984 10.4997 6.99984V5.83317H17.4997V6.99984C17.4997 7.69984 17.9663 8.1665 18.6663 8.1665C19.3663 8.1665 19.833 7.69984 19.833 6.99984V5.83317H22.1663C22.8663 5.83317 23.333 6.29984 23.333 6.99984V10.4998H4.66634V6.99984C4.66634 6.29984 5.13301 5.83317 5.83301 5.83317ZM5.83301 24.4998H22.1663C22.8663 24.4998 23.333 24.0332 23.333 23.3332V12.8332H4.66634V23.3332C4.66634 24.0332 5.13301 24.4998 5.83301 24.4998Z"
                fill="#212529"
            />
            <mask id="mask0_3253_13135" maskUnits="userSpaceOnUse" x="2" y="1" width="24" height="26">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M22.1663 3.49984H19.833V2.33317C19.833 1.63317 19.3663 1.1665 18.6663 1.1665C17.9663 1.1665 17.4997 1.63317 17.4997 2.33317V3.49984H10.4997V2.33317C10.4997 1.63317 10.033 1.1665 9.33301 1.1665C8.63301 1.1665 8.16634 1.63317 8.16634 2.33317V3.49984H5.83301C3.84967 3.49984 2.33301 5.0165 2.33301 6.99984V23.3332C2.33301 25.3165 3.84967 26.8332 5.83301 26.8332H22.1663C24.1497 26.8332 25.6663 25.3165 25.6663 23.3332V6.99984C25.6663 5.0165 24.1497 3.49984 22.1663 3.49984ZM5.83301 5.83317H8.16634V6.99984C8.16634 7.69984 8.63301 8.1665 9.33301 8.1665C10.033 8.1665 10.4997 7.69984 10.4997 6.99984V5.83317H17.4997V6.99984C17.4997 7.69984 17.9663 8.1665 18.6663 8.1665C19.3663 8.1665 19.833 7.69984 19.833 6.99984V5.83317H22.1663C22.8663 5.83317 23.333 6.29984 23.333 6.99984V10.4998H4.66634V6.99984C4.66634 6.29984 5.13301 5.83317 5.83301 5.83317ZM5.83301 24.4998H22.1663C22.8663 24.4998 23.333 24.0332 23.333 23.3332V12.8332H4.66634V23.3332C4.66634 24.0332 5.13301 24.4998 5.83301 24.4998Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_3253_13135)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H28V28H0V0Z" fill="#212529" />
            </g>
        </svg>
    );
};

export default CalendarIcon;
