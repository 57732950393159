import ArchiveTabPanelStyled from '../styles/ArchiveTabPanelStyled';
import { Box } from '@mui/material';

const ArchiveTabPanel = ({ children, value, index, ...other }) => {
    return (
        <ArchiveTabPanelStyled role="tabpanel" hidden={value !== index} {...other}>
            {value === index && <Box>{children}</Box>}
        </ArchiveTabPanelStyled>
    );
};

export default ArchiveTabPanel;
