import CalendarButton from '../styles/CalendarButton';
import dayjs from 'dayjs';
import { Box, Stack, ToggleButtonGroup } from '@mui/material';
import { useState } from 'react';

const buttons = [
    {
        text: 'Сегодня',
        value: 'today',
        onClick: ({ setStartDate, setEndDate, setRightMonth, setLeftMonth }) => {
            const startDate = dayjs();
            const endDate = dayjs();

            setLeftMonth(startDate.startOf('month'));
            setRightMonth(startDate.add(1, 'month').startOf('month'));

            setStartDate(startDate);
            setEndDate(endDate);
        },
    },
    {
        text: 'Вчера',
        value: 'yesterday',
        onClick: ({ setStartDate, setEndDate, setRightMonth, setLeftMonth }) => {
            const startDate = dayjs().subtract(1, 'day').startOf('day');
            const endDate = dayjs().subtract(1, 'day').endOf('day');

            setStartDate(startDate);
            setEndDate(endDate);
            if (startDate.isBefore(dayjs(), 'month')) {
                setLeftMonth(startDate.startOf('month'));
                setRightMonth(startDate.add(1, 'month').startOf('month'));
            }
        },
    },
    {
        text: 'Неделя',
        value: 'week',
        onClick: ({ setStartDate, setEndDate, setRightMonth, setHoverDate, setLeftMonth }) => {
            const startDate = dayjs().subtract(1, 'week').startOf('week').add(1, 'day');
            const endDate = dayjs().startOf('week');

            console.log('%c---startDate:', 'background: orange; color: black', startDate.format('YYYY-MM-DD'));
            console.log('%c---endDate:', 'background: orange; color: black', endDate.format('YYYY-MM-DD'));

            setHoverDate(null);
            setStartDate(startDate);
            setEndDate(endDate);

            setLeftMonth(startDate.startOf('month'));
            if (!endDate.isSame(startDate, 'month')) {
                setRightMonth(endDate.startOf('month'));
            }
        },
    },
    {
        text: 'Месяц',
        value: 'month',
        onClick: ({ setStartDate, setEndDate, setRightMonth, setLeftMonth, setHoverDate }) => {
            const startDate = dayjs().startOf('month');
            const endDate = dayjs().endOf('month');

            setHoverDate(null);
            setStartDate(startDate);
            setEndDate(endDate);
            setLeftMonth(startDate);
            setRightMonth(startDate.add(1, 'month').startOf('month'));
        },
    },
    {
        text: 'Квартал',
        value: 'quarter',
        onClick: ({ setStartDate, setEndDate, setRightMonth, setLeftMonth, setHoverDate }) => {
            const startDate = dayjs().startOf('quarter');
            const endDate = dayjs().endOf('quarter');

            setHoverDate(null);
            setStartDate(startDate);
            setEndDate(endDate);

            setLeftMonth(startDate);
            setRightMonth(endDate);
        },
    },
    {
        text: 'Год',
        value: 'year',
        onClick: ({ setStartDate, setEndDate, setRightMonth, setLeftMonth, setHoverDate }) => {
            const startDate = dayjs().startOf('year');
            const endDate = dayjs().endOf('year');

            setHoverDate(null);
            setStartDate(startDate);
            setEndDate(endDate);

            setLeftMonth(startDate);
            setRightMonth(endDate);
        },
    },
];

const DatePickerHeader = ({ alignment, setAlignment, setStartDate, setEndDate, setRightMonth, setLeftMonth, setHoverDate }) => {
    const handleChange = (_, newAlignment) => {
        setAlignment(newAlignment);
    };

    return (
        <Box>
            <ToggleButtonGroup value={alignment} onChange={handleChange} exclusive>
                <Stack direction={'row'} spacing={2}>
                    {buttons.map(({ text, value, onClick }) => (
                        <CalendarButton
                            disableRipple
                            onClick={() => onClick({ setStartDate, setEndDate, setRightMonth, setLeftMonth, setHoverDate })}
                            key={value}
                            value={value}
                        >
                            {text}
                        </CalendarButton>
                    ))}
                </Stack>
            </ToggleButtonGroup>
        </Box>
    );
};

export default DatePickerHeader;
