import DateCalendarHeader from './DateCalendarHeader';
import DateCalendarPickersDay from './DateCalendarPickersDay';
import DateCalendarStyled from '../styles/DateCalendarStyled';
import React, { useCallback } from 'react';
import { Box, Stack } from '@mui/material';
import { DateCalendar } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const DatePickerCalendars = (props) => {
    const { startDate, setStartDate, endDate, setEndDate, hoverDate, setHoverDate, leftMonth, setLeftMonth, rightMonth, setRightMonth } = props;

    // -- Функция для обработки месяца в левом календаре
    const handleLeftMonthChange = (newLeftMonth) => {
        setLeftMonth(newLeftMonth);
        if (newLeftMonth.isSameOrAfter(rightMonth, 'month')) {
            setRightMonth(newLeftMonth.add(1, 'month')); // Автоматически синхронизировать правый месяц
        }
    };

    // -- Функция для обработки месяца в правом календаре
    const handleRightMonthChange = (newRightMonth) => {
        setRightMonth(newRightMonth);
        if (newRightMonth.isSameOrBefore(leftMonth, 'month')) {
            setLeftMonth(newRightMonth.subtract(1, 'month')); // Автоматически синхронизировать левый месяц
        }
    };

    const handleDateSelect = useCallback(
        (date) => {
            if (!startDate || (startDate && endDate)) {
                setStartDate(date);
                setEndDate(null);
            } else if (startDate && !endDate) {
                if (date.isBefore(startDate)) {
                    setEndDate(startDate);
                    setStartDate(date);
                } else {
                    setEndDate(date);
                }
            }
        },
        [startDate, endDate],
    );

    const handleDayMouseEnter = useCallback(
        (date) => {
            setHoverDate(null);
            if (startDate && !endDate) {
                setHoverDate(date); // Обновляем hoverDate только если конечная дата не выбрана
            }
        },
        [startDate, endDate],
    );

    return (
        <Stack direction={'row'} justifyContent={'space-between'}>
            <DateCalendarStyled
                value={leftMonth}
                onChange={handleDateSelect}
                dayOfWeekFormatter={(date) => date.format('dd')}
                slots={{
                    day: (dayProps) => (
                        <DateCalendarPickersDay
                            {...dayProps}
                            startDate={startDate}
                            endDate={endDate}
                            hoverDate={hoverDate}
                            onDayMouseEnter={handleDayMouseEnter} // Обработка наведения
                            month={leftMonth}
                        />
                    ),
                    calendarHeader: () => (
                        <DateCalendarHeader
                            leftMonth={leftMonth}
                            rightMonth={rightMonth}
                            onLeftMonthChange={handleLeftMonthChange}
                            onRightMonthChange={handleRightMonthChange}
                            calendarType="left"
                        />
                    ),
                }}
            />
            <DateCalendarStyled
                value={rightMonth}
                onChange={handleDateSelect}
                dayOfWeekFormatter={(date) => date.format('dd')}
                slots={{
                    day: (dayProps) => (
                        <DateCalendarPickersDay
                            {...dayProps}
                            startDate={startDate}
                            endDate={endDate}
                            hoverDate={hoverDate}
                            onDayMouseEnter={handleDayMouseEnter} // Обработка наведения
                            calendarType={'right'}
                            month={rightMonth}
                        />
                    ),
                    calendarHeader: () => (
                        <DateCalendarHeader
                            leftMonth={leftMonth}
                            rightMonth={rightMonth}
                            onLeftMonthChange={handleLeftMonthChange}
                            onRightMonthChange={handleRightMonthChange}
                            calendarType="right"
                        />
                    ),
                }}
            />
        </Stack>
    );
};

export default DatePickerCalendars;
