import DatePickerBody from './DatePickerBody';
import DatePickerHeader from './DatePickerHeader';
import DatePickerWrapper from '../styles/DatePickerWrapper';
import React, { useState } from 'react';
import { ClickAwayListener, Stack } from '@mui/material';
import { createPortal } from 'react-dom';

const DatePickerPopover = ({ closeDatePicker, ...props }) => {
    const {
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        hoverDate,
        setHoverDate,
        leftMonth,
        setLeftMonth,
        rightMonth,
        setRightMonth,
        setOpen,
        applyCalendar,
        type,
        resetCalendar,
    } = props;
    const [alignment, setAlignment] = useState(null);

    const element = document.getElementById('date-picker-popover');

    return createPortal(
        <ClickAwayListener onClickAway={closeDatePicker}>
            <DatePickerWrapper>
                <Stack spacing={3.75}>
                    <DatePickerHeader
                        alignment={alignment}
                        setAlignment={setAlignment}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        setRightMonth={setRightMonth}
                        setLeftMonth={setLeftMonth}
                        setHoverDate={setHoverDate}
                    />
                    <DatePickerBody
                        type={type}
                        resetCalendar={resetCalendar}
                        applyCalendar={applyCalendar}
                        setOpen={setOpen}
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        hoverDate={hoverDate}
                        setHoverDate={setHoverDate}
                        leftMonth={leftMonth}
                        setLeftMonth={setLeftMonth}
                        rightMonth={rightMonth}
                        setRightMonth={setRightMonth}
                        setAlignment={setAlignment}
                    />
                </Stack>
            </DatePickerWrapper>
        </ClickAwayListener>,
        element,
    );
};

export default DatePickerPopover;
