const ArchiveDisableIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="1.25vw" height="1.25vw" viewBox="0 0 24 25" fill="rgb(148, 157, 175)">
            <path
                d="M22.2857 3.5H1.71429C1.25963 3.5 0.823593 3.68061 0.502103 4.0021C0.180612 4.32359 0 4.75963 0 5.21429V7.78571C0 8.24037 0.180612 8.67641 0.502103 8.9979C0.823593 9.31939 1.25963 9.5 1.71429 9.5V18.9286C1.71429 19.3832 1.8949 19.8193 2.21639 20.1408C2.53788 20.4622 2.97391 20.6429 3.42857 20.6429H20.5714C21.0261 20.6429 21.4621 20.4622 21.7836 20.1408C22.1051 19.8193 22.2857 19.3832 22.2857 18.9286V9.5C22.7404 9.5 23.1764 9.31939 23.4979 8.9979C23.8194 8.67641 24 8.24037 24 7.78571V5.21429C24 4.75963 23.8194 4.32359 23.4979 4.0021C23.1764 3.68061 22.7404 3.5 22.2857 3.5ZM20.5714 18.9286H3.42857V9.5H20.5714V18.9286ZM22.2857 7.78571H1.71429V5.21429H22.2857V7.78571ZM8.57143 12.9286C8.57143 12.7012 8.66173 12.4832 8.82248 12.3225C8.98323 12.1617 9.20124 12.0714 9.42857 12.0714H14.5714C14.7988 12.0714 15.0168 12.1617 15.1775 12.3225C15.3383 12.4832 15.4286 12.7012 15.4286 12.9286C15.4286 13.1559 15.3383 13.3739 15.1775 13.5347C15.0168 13.6954 14.7988 13.7857 14.5714 13.7857H9.42857C9.20124 13.7857 8.98323 13.6954 8.82248 13.5347C8.66173 13.3739 8.57143 13.1559 8.57143 12.9286Z"
                fill="rgb(148, 157, 175)"
            />
        </svg>
    );
};

export default ArchiveDisableIcon;
