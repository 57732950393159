import React from 'react';

import DocumentIcon from './svgComponents/DocumentIcon';

const DocumentButton = (props) => {
    const { isActive, onClick = () => {} } = props;
    return (
        <div onClick={onClick} className={`document-button ${isActive ? 'active' : ''}`}>
            <DocumentIcon className="document-icon" />
            Документы
        </div>
    );
};

export default DocumentButton;
