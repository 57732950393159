const ArrowForward = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="1.25vw" height="1.25vw" viewBox="0 0 24 24" fill="none">
            <path
                d="M15.7 12.7L9.7 18.7C9.5 18.9 9.3 19 9 19C8.7 19 8.5 18.9 8.3 18.7C7.9 18.3 7.9 17.7 8.3 17.3L13.6 12L8.3 6.7C7.9 6.3 7.9 5.7 8.3 5.3C8.7 4.9 9.3 4.9 9.7 5.3L15.7 11.3C16.1 11.7 16.1 12.3 15.7 12.7Z"
                fill="#212529"
            />
            <mask id="mask0_3708_14195" maskUnits="userSpaceOnUse" x="8" y="5" width="8" height="14">
                <path
                    d="M15.7 12.7L9.7 18.7C9.5 18.9 9.3 19 9 19C8.7 19 8.5 18.9 8.3 18.7C7.9 18.3 7.9 17.7 8.3 17.3L13.6 12L8.3 6.7C7.9 6.3 7.9 5.7 8.3 5.3C8.7 4.9 9.3 4.9 9.7 5.3L15.7 11.3C16.1 11.7 16.1 12.3 15.7 12.7Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_3708_14195)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H24V24H0V0Z" fill="#212529" />
            </g>
        </svg>
    );
};

export default ArrowForward;
