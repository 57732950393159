import CertificateIcon from '../svgComponents/CertificateIcon';
import React from 'react';

const NoDocumentInArchive = () => {
    return (
        <div className="no-document in-coming">
            <CertificateIcon />
            <h1>В вашем архиве пока нет документов</h1>
            <p>
                На этой странице будут показаны документы,
                <br />
                которые были отправлены в архив
            </p>
        </div>
    );
};

export default NoDocumentInArchive;
