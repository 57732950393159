const urls = {
    INCOMING_DOCS_URL: '/incoming-documents',
    OUTGOING_DOCS_URL: '/outgoing-documents',
    MESSAGES_URL: '/get-list',
    SEND_DOCUMENT_URL: '/send-document',
    DOWNLOAD_DOCUMENT_URL: '/download',
    READ_DOCUMENT_URL: '/read-document',
    UPDATE_DOCUMENT_URL: '/update-document',
    SIGN_DOCUMENT_URL: '/sign',
    ARCHIVE: '/archive',
    CONTRACTORS: '/get-counterparties',
};

export default urls;
