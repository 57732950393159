import CalendarIcon from "../../../../component/svgComponents/CalendarIcon";
import CalendarInputPaper from "../styles/CalendarInputPaper";
import CalendarInputStyled from "../../CustomCalendar/styles/CalendarInputStyled";
import React, { useState } from "react";
import { InputAdornment } from "@mui/material";

const DatePickerSlot = ({ openDatePicker, placeholder, normal, ...props }) => {
    const [border, setBorder] = useState(normal ? '1px solid #BDBDBD' : null);
    return (
        <CalendarInputPaper
            square
            sx={{
                border,
                cursor: 'pointer',
            }}
            onClick={openDatePicker}
        >
            <CalendarInputStyled
                placeholder={placeholder}
                disableUnderline
                onBlur={() => setBorder(normal ? '1px solid #BDBDBD' : null)}
                onFocus={() => setBorder('1px solid #8AB528')}
                {...props}
            />
        </CalendarInputPaper>
    );
};

export default DatePickerSlot;
