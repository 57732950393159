import '../styles/PickersDay.style.css';
import DateCalendarPickersDayStyled from '../styles/DateCalendarPickersDayStyled';
import React, { useCallback } from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { Box } from '@mui/material';

const DateCalendarPickersDay = ({ day, startDate, endDate, hoverDate, calendarType, month, onDayMouseEnter, ...other }) => {
    const isInCurrentMonth = (day, currentMonth) => {
        return day.isSame(currentMonth, 'month');
    };

    const isSelected = startDate && day.isSame(startDate, 'day') && isInCurrentMonth(day, month);
    const isEnd = endDate && day.isSame(endDate, 'day') && isInCurrentMonth(day, month);

    // -- Проверка, является ли день внутри диапазона
    const isInRange = useCallback(
        (day) => {
            if (!startDate) return false;

            if (endDate) {
                // Если конечная дата выбрана
                return day.isAfter(startDate, 'day') && day.isBefore(endDate, 'day');
            }

            if (hoverDate) {
                // Если конечная дата не выбрана, но есть hoverDate
                if (hoverDate.isAfter(startDate)) {
                    // Наведенный день больше, чем startDate
                    return day.isAfter(startDate, 'day') && day.isBefore(hoverDate, 'day');
                } else {
                    // Наведенный день меньше, чем startDate — инверсия диапазона
                    return day.isAfter(hoverDate, 'day') && day.isBefore(startDate, 'day');
                }
            }

            return false;
        },
        [startDate, endDate, hoverDate],
    );
    const isRange = isInRange(day);

    if (isInCurrentMonth(day, month)) {
        return (
            <Box
                className={clsx('day-container', {
                    'day-container__selected': isSelected || isEnd,
                    'day-container__range': isRange,
                    'day-container__hovered': day.isSame(hoverDate, 'day'),
                })}
                onMouseEnter={() => onDayMouseEnter(day)} // Обработчик наведения на день
            >
                <div
                    className={clsx({
                        'day-background':
                            (startDate || endDate || hoverDate) &&
                            !startDate?.isSame(hoverDate, 'day') &&
                            (day.isSame(startDate, 'day') || day.isSame(hoverDate, 'day') || day.isSame(endDate, 'day')),
                        start:
                            (endDate?.get('date') != startDate?.get('date') ||
                                endDate?.get('month') != startDate?.get('month') ||
                                endDate?.get('year') != startDate?.get('year')) &&
                            (startDate?.isSame(day, 'day') || day.isBefore(hoverDate, 'day') || day.isBefore(startDate, 'day')),
                        end:
                            (endDate?.get('date') != startDate?.get('date') ||
                                endDate?.get('month') != startDate?.get('month') ||
                                endDate?.get('year') != startDate?.get('year')) &&
                            (endDate?.isSame(day, 'day') || day.isAfter(hoverDate, 'day') || day.isAfter(startDate, 'day')),
                    })}
                ></div>
                <DateCalendarPickersDayStyled
                    {...other}
                    disableRipple
                    today={null}
                    calendarType={calendarType}
                    day={day}
                    selected={isSelected || isEnd}
                />
            </Box>
        );
    } else {
        return <DateCalendarPickersDayStyled {...other} />;
    }
};

export default DateCalendarPickersDay;
