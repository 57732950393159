import React from 'react';
import { Button, styled } from '@mui/material';

const StyledButton = styled((props) => <Button {...props} />)({
    padding: '0.417vw 1.042vw',
    border: '1px solid transparent',
    color: '#181818',
    border: '1px solid #BDBDBD',
    textTransform: 'none',
    borderRadius: 0,
    fontSize: '0.833vw',
    transition: 'none',
    '&:hover': {
        borderColor: '#181818',
        backgroundColor: '#181818',
        color: '#fff',
    },
    '&.MuiButton-success': {
        border: 'none',
        backgroundColor: '#8AB528',
        color: '#fff',
    },
    '&.MuiButton-success:hover': {
        border: 'none',
        backgroundColor: '#181818',
    },
});
const ButtonStyled = ({ children, ...props }) => {
    return <StyledButton {...props}>{children}</StyledButton>;
};

export default ButtonStyled;
