import { Tab, styled } from '@mui/material';

const ArchiveTab = styled(Tab)({
    border: '1px solid #BDBDBD',
    textTransform: 'none',
    height: '3.073vw',
    fontSize: '0.938vw',
    color: '#181818',
    padding: '0.833vw 1.042vw',
    '&:hover': {
        borderColor: '#181818',
    },
    '&.Mui-selected': {
        borderColor: 'transparent',
        backgroundColor: '#8AB528',
        color: '#fff',
    },
    '&.Mui-focusVisible': {
        backgroundColor: 'rgba(100, 95, 228, 0.32)',
    },
});

export default ArchiveTab;
