import { styled } from '@mui/material';
import { PickersDay } from '@mui/x-date-pickers';

const DateCalendarPickersDayStyled = styled(PickersDay)({
    backgroundColor: 'inherit',
    color: 'inherit',
    padding: 0,
    margin: 0,
    fontWeight: 400,
    color: 'rgba(24, 24, 24, 1)',
    width: '2.604vw',
    height: '2.604vw',
    transition: 'none',

    '&:hover': {
        backgroundColor: 'inherit',
        color: 'inherit',
    },
    '&.Mui-selected': {
        backgroundColor: 'inherit',
        color: 'inherit',
    },
    '&.Mui-today': {
        backgroundColor: 'inherit',
        color: 'inherit',
        border: 'none',
    },
    '&.Mui-selected:hover': {
        backgroundColor: 'inherit',
    },
    '&.Mui-selected:focus': {
        backgroundColor: 'inherit',
    },
});

export default DateCalendarPickersDayStyled;
