import CalendarIcon from '../../../component/svgComponents/CalendarIcon';
import DatePickerPopover from './parts/DatePickerPopover';
import DatePickerSlot from './parts/DatePickerSlot';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { InputAdornment } from '@mui/material';

const DatePicker = ({ startDate, setStartDate, endDate, setEndDate, open, setOpen, applyCalendar, resetCalendar, type }) => {
    const [hoverDate, setHoverDate] = useState(null); // Дата под курсором
    const [leftMonth, setLeftMonth] = useState(dayjs());
    const [rightMonth, setRightMonth] = useState(dayjs().startOf('month').add(1, 'month'));
    const [valueDatePickerSlot, setValueDatePickerSlot] = useState('');

    const openDatePicker = () => {
        setOpen(true);
    };
    const closeDatePicker = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (startDate && endDate) {
            setValueDatePickerSlot(`${startDate?.format('DD.MM.YYYY')} - ${endDate?.format('DD.MM.YYYY')}`);
        } else {
            setValueDatePickerSlot('');
        }
    }, [startDate, endDate]);

    return (
        <>
            <DatePickerSlot
                value={valueDatePickerSlot}
                openDatePicker={openDatePicker}
                placeholder={!startDate && !endDate ? 'Укажите период...' : 'ДД.ММ.ГГГГ – ДД.ММ.ГГГГ'}
                endAdornment={
                    <InputAdornment position="end">
                        <CalendarIcon />
                    </InputAdornment>
                }
            />
            {open && (
                <DatePickerPopover
                    type={type}
                    applyCalendar={applyCalendar}
                    resetCalendar={resetCalendar}
                    setOpen={setOpen}
                    closeDatePicker={closeDatePicker}
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    hoverDate={hoverDate}
                    setHoverDate={setHoverDate}
                    leftMonth={leftMonth}
                    setLeftMonth={setLeftMonth}
                    rightMonth={rightMonth}
                    setRightMonth={setRightMonth}
                />
            )}
        </>
    );
};

export default DatePicker;
