import { configureStore, isRejectedWithValue } from '@reduxjs/toolkit';
import { documentsApi } from 'store/documentsApi';
import documentsSlice from 'store/documentsSlice';

const rtkQueryErrorLogger = () => (next) => (action) => {
    if (isRejectedWithValue(action)) {
        console.log('%cОшибка запроса:', 'background: red; color: yellow', action.payload);
    }

    return next(action);
};

export default configureStore({
    reducer: {
        documentsSlice: documentsSlice,
        [documentsApi.reducerPath]: documentsApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat(rtkQueryErrorLogger, documentsApi.middleware),
});
