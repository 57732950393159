import CancelIcon from '../../component/svgComponents/CancelIcon';
import { Box, Stack, Typography, styled } from '@mui/material';

const TypographyStyled = styled(Typography)({
    boxSizing: 'border-box',
    border: '1px solid transparent',
    fontSize: '0.833vw',
    marginLeft: '0.625vw',
});

const BoxStyled = styled(Box)({
    width: 'fit-content',
    cursor: 'pointer',
    marginTop: '1.042vw',
    display: 'flex',
    '&:hover': {
        '& .MuiTypography-root': {
            borderBottom: '1px solid #181818',
        },
    },
});

const ButtonFilter = ({ resetFilters }) => {
    return (
        <BoxStyled onClick={resetFilters}>
            <CancelIcon />
            <TypographyStyled>Сбросить фильтры</TypographyStyled>
        </BoxStyled>
    );
};

export default ButtonFilter;
