import '../../css/ModalBlockUser.css';
import Button from './Button';
import ButtonStyled from './ButtonStyled/ButtonStyled';
import ErrorBigIcon from '../../component/svgComponents/ErrorBigIcon';
import Logo from '../../component/svgComponents/Logo';
import React, { useContext, useEffect, useState } from 'react';
import { Modal } from '@mui/material';
import { TabsContext } from '../../helpers/TabsContext';

const ModalBlockUser = ({ user }) => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (user.is_blocked) {
            setIsOpen(true);
        }
    }, [user]);

    const { setCurrentTab, setCurrentPageTab } = useContext(TabsContext);

    return (
        <Modal open={isOpen} onClose={() => setIsOpen(false)}>
            <div className="modal block-user">
                <div className="block-user__container">
                    <div className="block-user__icon">
                        <Logo width={'5.417vw'} height={'3.333vw'} />
                    </div>
                    <div className="block-user__error-message">
                        <div className="block-user__error-icon">
                            <ErrorBigIcon width={'2.5vw'} height={'2.5vw'} />
                        </div>
                        <span className="block-user__error-message-text">Ваш аккаунт временно заблокирован.</span>
                        <span className="block-user__error-message-text">Вы можете только подписывать входящие документы</span>
                    </div>
                    <div className="block-user__description">
                        <span style={{ display: 'block', paddingBottom: '15px' }} className="block-user__description-text">
                            Для дальнейшей работы и придания юридической значимости вашему документообороту вам необходимо подписать договор
                            с ООО «Удобная Подпись».
                        </span>
                    </div>
                    <div className="block-user__contacts">
                        <span className="block-user__contacts-header">По вопросу подписания договора свяжитесь со службой поддержки:</span>
                        <span className="block-user__contacts-text">Телефон / WhatsApp: +7 (926) 100-43-14</span>
                        <span className="block-user__contacts-text">E-mail: support@simpsign.com</span>
                    </div>
                    <ButtonStyled
                        variant="success"
                        onClick={() => {
                            setIsOpen(false);
                            setCurrentPageTab(0);
                            setCurrentTab(0);
                        }}
                    >
                        Войти в Личный кабинет
                    </ButtonStyled>
                </div>
            </div>
        </Modal>
    );
};

export default ModalBlockUser;
