import React from 'react';

function CertificateIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="8.3vw" height="8vw" fill="none" viewBox="0 0 160 155">
            <path
                fill="#000"
                d="M111.196 136.258a.846.846 0 01-.415-.109c-.37-.208-37.532-20.823-89.725-24.265a.846.846 0 01-.79-.899.85.85 0 01.902-.787c47.846 3.155 83.18 20.652 89.453 23.946L138.74 12.88 40.793 4.933 27.038 94.658a.845.845 0 01-.967.708.846.846 0 01-.71-.964L39.237 3.898a.833.833 0 01.908-.715l99.711 8.092a.844.844 0 01.758 1.033l-28.59 123.296a.85.85 0 01-.827.654z"
            ></path>
            <path fill="#FEA000" d="M58.926 17.28l55.043 5.398-6.785 27.614-52.637-12.035 4.379-20.977z"></path>
            <path
                fill="#000"
                d="M94.047 67.107a.882.882 0 01-.214-.027l-5.472-1.42a.845.845 0 11.427-1.636l5.472 1.42a.844.844 0 01-.213 1.663zm-10.942-2.84a.85.85 0 01-.215-.027l-5.472-1.42a.845.845 0 11.428-1.634l5.472 1.42a.845.845 0 01-.213 1.662zM72.16 61.43a.89.89 0 01-.214-.027l-5.472-1.42a.845.845 0 11.428-1.636l5.472 1.42a.846.846 0 01-.214 1.663zm-10.943-2.84a.861.861 0 01-.214-.027l-5.472-1.42a.845.845 0 11.428-1.636l5.472 1.42a.844.844 0 01-.214 1.664zm-10.943-2.838a.851.851 0 01-.214-.028l-5.472-1.42a.845.845 0 11.427-1.635l5.473 1.42a.844.844 0 01-.214 1.663zM85.293 84.68a.887.887 0 01-.156-.014l-.728-.133a.848.848 0 01-.68-.986.85.85 0 01.99-.677l.728.134c.46.086.764.527.68.986a.847.847 0 01-.834.69zm-6.287-1.163a.857.857 0 01-.156-.013l-5.558-1.029a.845.845 0 11.309-1.662l5.558 1.028a.845.845 0 01-.153 1.676zm-11.117-2.055a.854.854 0 01-.156-.015l-5.56-1.028a.846.846 0 11.31-1.662l5.558 1.028a.845.845 0 01-.152 1.677zM56.77 79.405a.818.818 0 01-.157-.014l-5.557-1.028a.845.845 0 11.309-1.662l5.558 1.028a.845.845 0 01-.154 1.676z"
            ></path>
            <path
                fill="#fff"
                d="M86.801 34.862a2.338 2.338 0 002.342-2.335 2.338 2.338 0 00-2.342-2.335 2.338 2.338 0 00-2.342 2.335 2.338 2.338 0 002.342 2.335zM99.104 39.53c2.587 0 4.685-2.09 4.685-4.668 0-2.58-2.098-4.67-4.685-4.67a4.677 4.677 0 00-4.684 4.67 4.677 4.677 0 004.684 4.669z"
            ></path>
            <path
                fill="#8AB528"
                d="M77.192 126.834s-4.428 16.665-3.76 18.292c.668 1.626 11.882-3.397 13.965-2.967 2.082.432 17.084 11.353 18.262 10.382 1.176-.972-6.983-28.178-6.983-28.178l-21.484 2.471z"
            ></path>
            <path
                fill="#8AB528"
                d="M87.429 134.29c10.474 0 18.964-8.463 18.964-18.903 0-10.439-8.49-18.903-18.964-18.903-10.474 0-18.964 8.464-18.964 18.903 0 10.44 8.49 18.903 18.964 18.903z"
            ></path>
            <path
                fill="#fff"
                d="M102.66 115.168c0 8.024-6.529 14.532-14.579 14.532s-14.579-6.507-14.579-14.532c0-8.024 6.529-14.531 14.58-14.531 8.049-.001 14.578 6.506 14.578 14.531z"
            ></path>
            <path
                fill="#8AB528"
                d="M88.178 123.429c4.695 0 8.502-3.794 8.502-8.474s-3.807-8.475-8.502-8.475c-4.696 0-8.502 3.795-8.502 8.475s3.806 8.474 8.502 8.474z"
            ></path>
        </svg>
    );
}

export default CertificateIcon;
