import ArrowBack from '../../../../component/svgComponents/ArrowBack';
import ArrowForward from '../../../../component/svgComponents/ArrowForward';
import React from 'react';
import { IconButton, Stack, Typography } from '@mui/material';

const DateCalendarHeader = ({ leftMonth, rightMonth, onLeftMonthChange, onRightMonthChange, calendarType }) => {
    const isLeft = calendarType === 'left';
    // -- Обработка переключения месяца влево (уменьшение месяца)
    const handlePrevMonth = () => {
        if (isLeft) {
            const newLeftMonth = leftMonth.subtract(1, 'month');
            onLeftMonthChange(newLeftMonth);
        } else {
            const newRightMonth = rightMonth.subtract(1, 'month');
            onRightMonthChange(newRightMonth);
        }
    };

    // -- Обработка переключения месяца вправо (увеличение месяца)
    const handleNextMonth = () => {
        if (isLeft) {
            const newLeftMonth = leftMonth.add(1, 'month');
            onLeftMonthChange(newLeftMonth);
        } else {
            const newRightMonth = rightMonth.add(1, 'month');
            onRightMonthChange(newRightMonth);
        }
    };

    return (
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} paddingBottom={'1.302vw'}>
            <IconButton onClick={handlePrevMonth}>
                <ArrowBack />
            </IconButton>
            <Typography
                sx={{
                    textTransform: 'capitalize',
                    fontSize: '0.938vw',
                    fontWeight: 600,
                }}
            >
                {isLeft ? leftMonth?.locale('ru').format('MMMM YYYY') : rightMonth?.locale('ru').format('MMMM YYYY')}
            </Typography>

            <IconButton onClick={handleNextMonth}>
                <ArrowForward />
            </IconButton>
        </Stack>
    );
};

export default DateCalendarHeader;
