import React from 'react';

import LabTabsCounterParty from './LabTabsCounterParty';
import { Box } from '@mui/material';

const CounterParty = () => {
    return (
        <div className="document-header">
            Контрагенты
            <Box
                sx={{
                    marginRight: '4.16vw',
                }}
            >
                <LabTabsCounterParty />
            </Box>
        </div>
    );
};

export default CounterParty;
