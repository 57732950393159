import React, { useContext } from 'react';

import LabTabsDocument from './LabTabsDocument';
import { TabsContext } from '../helpers/TabsContext';
import { UserContext } from '../helpers/UserContext';
import { Box, Stack, Typography } from '@mui/material';
import BackIcon from './svgComponents/BackIcon';

const DOCUMENT_TAB_INDEX = 0;

const Document = () => {
    const { currentTab, currentPageTab, setCurrentTab, setCurrentPageTab } = useContext(TabsContext);
    const { user } = useContext(UserContext);
    const isArchiveTab = user?.is_blocked ? currentPageTab === 2 && currentTab === 0 : currentPageTab === 3 && currentTab === 0;
    const handleClick = (index) => {
        setCurrentTab(index);
        setCurrentPageTab(user?.is_blocked ? 0 : 1);
        localStorage.setItem('Tab', JSON.stringify({ currentTab: index, currentPageTab: user?.is_blocked ? 0 : 1 }));
    };

    return (
        <div className="document-header">
            {isArchiveTab && (
                <Stack
                    direction={'row'}
                    alignItems={'center'}
                    spacing={'0.882vw'}
                    sx={{
                        color: '#949DAF',
                        fontSize: '1.25vw',
                        fontWeight: '500',
                        cursor: 'pointer',
                    }}
                    onClick={() => handleClick(DOCUMENT_TAB_INDEX)}
                >
                    <BackIcon />
                    <span>Документы</span>
                </Stack>
            )}
            {!isArchiveTab && (
                <Box sx={{ marginTop: '2.318vw' }}>
                    <span>Документы</span>
                </Box>
            )}
            <LabTabsDocument isArchiveTab={isArchiveTab} />
        </div>
    );
};

export default Document;
