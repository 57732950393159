import { Tabs, styled } from '@mui/material';

const ArchiveTabs = styled(Tabs)({
    'button + button': {
        marginLeft: '1.042vw',
    },
    '& .MuiTabs-indicator': {
        display: 'none',
    },
});

export default ArchiveTabs;
