import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import typeOfDocumentTabs from 'constants/typeOfDocumentTabs';
import urls from 'constants/urls';
import { getToken } from 'helpers';

export const serializeObject = (documentObject) => ({
    id: documentObject.ext_document_id,
    date: documentObject.date,
    document: documentObject.document_name,
    recipientDocumentStatus: documentObject.recipient_document_status.name,
    senderDocumentStatus: documentObject.sender_document_status.name,
    recipientSignatureStatus: documentObject.recipient_signature_status?.name ?? '',
    senderSignatureStatus: documentObject.sender_signature_status.name,
    shelfLife: documentObject.expires_at,
    checked: false,
    workflowId: documentObject.ext_workflow_id,
    counterpartyIdentificationNumber: documentObject.counterparty_identification_number,
});

export const documentsApi = createApi({
    reducerPath: 'documentsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASE_URL,
        prepareHeaders: (headers) => {
            headers.set('Authorization', `Bearer ${getToken().replace(/"/g, ' ')}`);
            return headers;
        },
    }),
    endpoints: (builder) => {
        return {
            getIncomingDocuments: builder.query({
                query: ({ searchValue = '', betweenDate = '' }) => {
                    return {
                        url: `${urls.INCOMING_DOCS_URL}?${searchValue ? `search=${encodeURIComponent(searchValue)}` : ''}${
                            betweenDate ? `&betweenDate=${betweenDate}` : ''
                        }`,
                        method: 'GET',
                    };
                },
                transformResponse: (response = []) => {
                    return response.map((document) => {
                        const serializedObject = serializeObject(document);
                        return {
                            ...serializedObject,
                            company: document.sender_name,
                            senderId: document.sender_id,
                            is_read: document.is_read_recipient,
                        };
                    });
                },
                providesTags: ['putInArchive'],
            }),
            getOutgoingDocuments: builder.query({
                query: ({ searchValue = '', betweenDate = '' }) => {
                    return {
                        url: `${urls.OUTGOING_DOCS_URL}?${searchValue ? `search=${encodeURIComponent(searchValue)}` : ''}${
                            betweenDate ? `&betweenDate=${betweenDate}` : ''
                        }`,
                        method: 'GET',
                    };
                },
                transformResponse: (response = []) => {
                    return response.map((document) => {
                        const serializedObject = serializeObject(document);
                        return {
                            ...serializedObject,
                            company: document.recipient_name,
                            is_read: document.is_read_sender,
                        };
                    });
                },
                providesTags: ['putInArchive'],
            }),
            getArchive: builder.query({
                query: ({ searchValue = '', betweenDate = '', type = '' }) => {
                    return {
                        url: `${urls.ARCHIVE}?${searchValue ? `search=${encodeURIComponent(searchValue)}` : ''}${
                            betweenDate ? `&betweenDate=${betweenDate}` : ''
                        }${type ? `&type=${type}` : ''}`,
                        method: 'GET',
                    };
                },
                transformResponse: (response = []) => {
                    const incomingArchiveDocuments = response?.incoming_files?.map((document) => {
                        const serializedObject = serializeObject(document);
                        return {
                            ...serializedObject,
                            company: document.sender_name,
                            senderId: document.sender_id,
                            is_read: document.is_read_recipient,
                        };
                    });
                    const outgoingArchiveDocuments = response?.outgoing_files?.map((document) => {
                        const serializedObject = serializeObject(document);
                        return {
                            ...serializedObject,
                            company: document.recipient_name,
                            is_read: document.is_read_sender,
                        };
                    });
                    return {
                        incoming_files: incomingArchiveDocuments,
                        outgoing_files: outgoingArchiveDocuments,
                    };
                },
            }),
            sortContractors: builder.query({
                query: ({ sortBy, sortOrder }) => {
                    return {
                        url: `${urls.CONTRACTORS}?${sortBy && `sortBy=${sortBy}`}${sortOrder && `&sortOrder=${sortOrder}`}`,
                        method: 'GET',
                    };
                },
            }),
            putInArchive: builder.mutation({
                query: ({ ...data }) => {
                    return {
                        url: urls.ARCHIVE,
                        method: 'PATCH',
                        body: data,
                    };
                },
                invalidatesTags: ['putInArchive'],
            }),
        };
    },
});

export const {
    useGetIncomingDocumentsQuery,
    useGetOutgoingDocumentsQuery,
    useLazyGetIncomingDocumentsQuery,
    useLazyGetOutgoingDocumentsQuery,
    useLazyGetArchiveQuery,
    usePutInArchiveMutation,
    useLazySortContractorsQuery,
} = documentsApi;
