import React from 'react';
import Lupa from './svgComponents/Lupa';

const NoDocuments = ({ resetFilters }) => {
    return (
        <div className="no-document in-coming">
            <Lupa />
            <h1>Ничего не найдено</h1>
            <p>Попробуйте сбросить фильтры и поискать ещё раз</p>
            <button onClick={resetFilters} className="signature-sign signature-button--expanded">
                Сбросить фильтры
            </button>
        </div>
    );
};

export default NoDocuments;
