import React from 'react';

function CancelIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="1.25vw" height="1.3vw" fill="none" viewBox="0 0 24 25">
            <path
                fill="#212529"
                fillRule="evenodd"
                d="M12 1.5c-6.1 0-11 4.9-11 11s4.9 11 11 11 11-4.9 11-11-4.9-11-11-11zm0 20c-5 0-9-4-9-9s4-9 9-9 9 4 9 9-4 9-9 9zm1.4-9l2.3-2.3c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0L12 11.1 9.7 8.8c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l2.3 2.3-2.3 2.3c-.4.4-.4 1 0 1.4.2.2.4.3.7.3.3 0 .5-.1.7-.3l2.3-2.3 2.3 2.3c.2.2.4.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4l-2.3-2.3z"
                clipRule="evenodd"
            ></path>
            <mask id="mask0_973_551" style={{ maskType: 'luminance' }} width="22" height="23" x="1" y="1" maskUnits="userSpaceOnUse">
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    d="M12 1.5c-6.1 0-11 4.9-11 11s4.9 11 11 11 11-4.9 11-11-4.9-11-11-11zm0 20c-5 0-9-4-9-9s4-9 9-9 9 4 9 9-4 9-9 9zm1.4-9l2.3-2.3c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0L12 11.1 9.7 8.8c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l2.3 2.3-2.3 2.3c-.4.4-.4 1 0 1.4.2.2.4.3.7.3.3 0 .5-.1.7-.3l2.3-2.3 2.3 2.3c.2.2.4.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4l-2.3-2.3z"
                    clipRule="evenodd"
                ></path>
            </mask>
            <g mask="url(#mask0_973_551)">
                <path fill="#212529" fillRule="evenodd" d="M0 .5h24v24H0V.5z" clipRule="evenodd"></path>
            </g>
        </svg>
    );
}

export default CancelIcon;
