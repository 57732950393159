import { Button, ToggleButton, styled } from '@mui/material';
import { DateCalendar } from '@mui/x-date-pickers';

const DateCalendarStyled = styled(DateCalendar)({
    maxWidth: '18.229vw',
    width: '100%',
    padding: 0,
    margin: 0,
    maxHeight: 'unset',
    height: 'auto',
    '& .MuiDayCalendar-header': {
        width: '100%',
        justifyContent: 'space-between',
        textTransform: 'capitalize',
        span: {
            fontSize: '0.833vw',
            padding: 0,
            margin: 0,
            width: '2.604vw',
            height: '2.604vw',
        },
    },

    '& .MuiDayCalendar-slideTransition': {
        overflow: 'unset',
        minHeight: '16.833vw',
    },

    '& .MuiDayCalendar-weekContainer': {
        justifyContent: 'space-between',
        transition: 'none',
        button: {
            fontSize: '0.833vw',
        },
    },
});

export default DateCalendarStyled;
