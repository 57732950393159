import Content from '../component/Content';
import ModalBlockUser from '../components/ui/ModalBlockUser';
import ModalWelcome from '../component/Modal/ModalWelcome';
import ROLE_CONSTANTS from '../utils/RolesConstants';
import React, { useEffect, useState } from 'react';
import SideBar from '../component/SideBar';
import api from '../api/api';
import { useLocation, useNavigate } from 'react-router-dom';
import { ScrollContextProvider } from '../helpers/ScrollContext';
import { TabsContextProvider } from '../helpers/TabsContext';
import { UserContextProvider } from '../helpers/UserContext';

const UserPage = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState({});
    const [isOpen, setIsOpen] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const findUser = async () => {
            try {
                return await api.get(`/find-user`);
            } catch (e) {
                alert(e);
                navigate('/login');
            }
        };
        void findUser().then((res) => {
            if (res?.data) {
                const checkOnAdminRole = res.data.role.some((value, index, array) => value.id === ROLE_CONSTANTS.admin);
                setIsAdmin(checkOnAdminRole);
                setUser(res.data);
                setIsLoading(false);
            }
        });
    }, []);

    return (
        <ScrollContextProvider>
            <UserContextProvider user={user} isAdmin={isAdmin}>
                <TabsContextProvider>
                    <div className="user-page">
                        <SideBar />
                        <Content isLoading={isLoading} />
                        <ModalWelcome isOpen={isOpen} setIsOpen={setIsOpen} user={user} />
                        <ModalBlockUser user={user} />
                    </div>
                </TabsContextProvider>
            </UserContextProvider>
        </ScrollContextProvider>
    );
};

export default UserPage;
